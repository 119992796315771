<template>
  <div class="container p0" id="programCompent" :class="{ display: step == 4 }">
    <div v-show="step == 1 || step == 4">
      <jl-form :columns="columns" :option="btnOption"></jl-form>
    </div>
    <drag
      v-show="step == 2"
      @step-msg2="stepEvent"
      :screen="info"
      @content="getContent"
    ></drag>
    <div v-show="step == 3">
      <jl-table
        :tableData="tableData"
        @init="ininTable"
        @handleSelectionChange="handleSelectionChange"
        :options="options"
        :columns="tableColumns"
        :operates="operates"
        :selectDefault="selectDefaultArray"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
      <div class="m-t text-center">
        <el-button class="cancel" type="primary" @click="changeStepVal(2)">
          {{ $t("i18n.back") }}
        </el-button>
        <el-button class="confirm" type="primary" @click="stepThree">
          {{ $t("i18n.confirm") }}
        </el-button>
      </div>
    </div>
    <div class="jlTableDiv" v-show="step == 4">
      <jl-table
        :tableData="submit.deviceList"
        :options="showOptions"
        :columns="showTableColumns"
        :operates="operates"
        :height="300"
      >
      </jl-table>
      <div v-show="step == 3" class="m-t text-center">
        <el-button class="cancel" type="primary" @click="changeStepVal(2)">
          {{ $t("i18n.back") }}
        </el-button>
        <el-button class="confirm" type="primary" @click="stepThree">
          {{ $t("i18n.confirm") }}
        </el-button>
      </div>
    </div>
    <el-dialog
      custom-class="previewDialog"
      :title="$t('i18n.preview')"
      v-model="dialogPreviewVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <preview :content="info"></preview>
    </el-dialog>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, toRefs } from "vue";
import jlForm from "../../components/form";
import jlTable from "../../components/table";
import preview from "./Preview";
import drag from "./Drag";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "Program",
  components: {
    jlTable,
    jlForm,
    drag,
    preview,
  },
  setup(props, { emit }) {
    emit("public_header", false);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      columns: [
        // 需要展示的列
        {
          prop: "name",
          label: "programName",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "date",
          label: "broadcastDate",
          type: "dateRange",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "time",
          label: "timeSlot",
          type: "timeRange",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "screen",
          label: "playArea",
          type: "select",
          value: "1",
          props: { label: "locale", value: "value" },
          data: [
            { locale: "homeScreen", value: "1" },
            { locale: "secondaryScreen", value: "2" },
          ],
        },
        {
          prop: "note",
          label: "programNotes",
          type: "textarea",
        },
        {
          label: "programSize",
          format: () => {
            return state.screenInfo.resolution;
          },
          show: false,
        },
        {
          label: "programDirection",
          format: () => {
            return state.screenInfo.direction == 1
              ? t("i18n.horizontalScreen")
              : t("i18n.verticalScreen");
          },
          show: false,
        },
        {
          label: "preview",
          type: "button",
          buttonLabel: "preview",
          show: false,
          method: () => {
            state.dialogPreviewVisible = true;
            // state.info = state.submit.content;
          },
        },
      ],
      btnOption: [
        {
          label: "nextStep",
          class: "primary",
          show: true,
          method: (row, validate) => {
            validate.then(() => {
              changeStepVal(2);
            });
          },
        },
        {
          label: "confirm",
          class: "primary",
          show: false,
          method: (row, validate) => {
            validate.then(() => {
              state.submit.name = row.name;
              state.submit.screen = row.screen;
              state.submit.memo = row.note;
              state.submit.effectiveDateBegin = proxy.$defined.format(
                row.date[0]
              );
              state.submit.effectiveDateEnd = proxy.$defined.format(
                row.date[1]
              );
              state.submit.effectiveTimeBegin = proxy.$defined.format(
                row.time[0],
                "hh:mm:ss"
              );
              state.submit.effectiveTimeEnd = proxy.$defined.format(
                row.time[1],
                "hh:mm:ss"
              );
              onSubmit();
            });
          },
        },
        {
          label: "back",
          class: "cancel",
          show: false,
          method: () => {
            changeStepVal(3);
          },
        },
      ],
      step: 1,
      submit: { deviceList: [] },
      info: "", // 节目设计版本内容
      screenInfo: "",
      tableData: [],
      options: {
        // table 的参数
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: true, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      showOptions: {
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: false, // 是否翻页
      },
      tableColumns: [
        // 需要展示的列
        {
          prop: "name",
          label: "deviceName",
          align: "center",
        },
        {
          prop: "mac",
          label: "deviceMAC",
          align: "center",
        },
        {
          prop: "sn",
          label: "deviceSN",
          align: "center",
        },
        {
          prop: "groups",
          label: "organization",
          align: "center",
        },
        {
          prop: "direction",
          label: "deviceDirection",
          align: "center",
          formatter: (row) => {
            return row.direction == 1
              ? t("i18n.horizontalScreen")
              : row.direction == -1
              ? t("i18n.none")
              : t("i18n.verticalScreen");
          },
        },
        {
          label: "directionAdaptation",
          align: "center",
          formatter: (row) => {
            if (row.direction == state.screenInfo.direction) {
              return `<span style="color: #67c23a">可用</span>`;
            } else {
              return `<span style="color: #f56c6c">不可用</span>`;
            }
          },
        },
        {
          prop: "lastModifiedDate",
          label: "lastModifyDate",
          align: "center",
        },
        {
          prop: "status",
          label: "status",
          align: "center",
          formatter: (row) => {
            if (row.status == 1) {
              return `<span style="color: #67c23a">${t("i18n.online")}</span>`;
            } else {
              return `<span style="color: #f56c6c">${t("i18n.offline")}</span>`;
            }
          },
        },
      ],
      showTableColumns: [
        {
          prop: "name",
          label: "deviceName",
          align: "center",
        },
        {
          prop: "mac",
          label: "deviceMAC",
          align: "center",
        },
        {
          prop: "sn",
          label: "deviceSN",
          align: "center",
        },
        {
          prop: "groups",
          label: "organization",
          align: "center",
        },
        {
          prop: "direction",
          label: "deviceDirection",
          align: "center",
          formatter: (row) => {
            return row.direction == 1
              ? t("i18n.horizontalScreen")
              : row.direction == -1
              ? t("i18n.none")
              : t("i18n.verticalScreen");
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [],
      },
      total: 0,
      dialogPreviewVisible: false,
      selectDefaultArray: [],
    });

    const init = () => {
      if (route.query.id) {
        editForData(route.query.id);
      } else {
        setDefaultData();
      }
    };

    const editForData = async (id) => {
      let { data } = await proxy.$api.advert.programDetail(id);
      state.submit = data;
      initData();
      showDevice(id);
    };

    const initData = () => {
      state.columns.map((item) => {
        item.isUpdate = true;
        if (item.prop === "name") {
          item.value = state.submit.name;
        } else if (item.prop === "date") {
          let date = [
            state.submit.effectiveDateBegin,
            state.submit.effectiveDateEnd,
          ];
          item.value = date;
        } else if (item.prop === "time") {
          let now = proxy.$defined.format(new Date());
          let time = [
            now + " " + state.submit.effectiveTimeBegin,
            now + " " + state.submit.effectiveTimeEnd,
          ];
          item.value = time;
        } else if (item.prop === "screen") {
          item.value = state.submit.screen;
        } else if (item.prop === "note") {
          item.value = state.submit.memo;
        }
      });
      state.info = state.submit.content;
    };

    const showDevice = async (id) => {
      let { data } = await proxy.$api.advert.getDeviceByProgram(id);
      state.submit.deviceList = JSON.parse(JSON.stringify(data));
      state.selectDefaultArray = data;
    };

    const ininTable = async (params) => {
      params.descs = "created_date";
      const { data } = await proxy.$api.advert.deviceList(params);
      state.tableData = data.records;
      state.total = data.total;
    };

    const setDefaultData = () => {
      var month =
        new Date().getMonth() + 1 < 10
          ? "0" + (new Date().getMonth() + 1)
          : new Date().getMonth() + 1;
      var month1 =
        new Date().getMonth() + 2 < 10
          ? "0" + (new Date().getMonth() + 2)
          : new Date().getMonth() + 2;
      var day =
        new Date().getDate() < 10
          ? "0" + new Date().getDate()
          : new Date().getDate();
      var currentDay = new Date().getFullYear() + "-" + month + "-" + day;
      var nextDay = new Date().getFullYear() + "-" + month1 + "-" + day;
      state.columns[1].isUpdate = true;
      state.columns[1].value = [currentDay, nextDay];
      state.columns[2].isUpdate = true;
      state.columns[2].value = [
        currentDay + " 00:00:00",
        currentDay + " 23:59:59",
      ];
    };

    const changeStepVal = (val) => {
      state.step = val;
      changeShow(val);
      emit("step-msg", val);
    };

    const stepEvent = (val) => {
      state.step = val;
      changeShow(val);
    };

    const changeShow = (val) => {
      state.btnOption[0].show = val == 1;
      state.btnOption[1].show = val == 4;
      state.btnOption[2].show = val == 4;
      state.columns.map((item, index) => {
        if (index > 4) {
          item.show = val == 4;
        }
      });
    };

    const getContent = (data) => {
      state.submit.content = data;
      state.screenInfo = JSON.parse(data);
    };

    const stepThree = () => {
      if (isRightDirection(state.submit.deviceList)) {
        changeStepVal(4);
      }
    };

    const handleSelectionChange = (val) => {
      if (isRightDirection(val)) {
        state.submit.deviceList = val;
      }
    };

    const isRightDirection = (val) => {
      var arr = val.filter(
        (row) => row.direction != state.screenInfo.direction
      );
      if (arr.length > 0 && state.screenInfo.direction) {
        proxy.$defined.tip(t("i18n.directionOnErrorTip"));
        return false;
      } else {
        return true;
      }
    };
    const onSubmit = async () => {
      state.submit.id
        ? await proxy.$api.advert.editProgram(state.submit)
        : await proxy.$api.advert.addProgram(state.submit);
      let tip = state.submit.id ? "edit" : "new";
      proxy.$defined.tip(t("i18n." + tip) + t("i18n.success"), "success");
      router.push({ path: "/advert/programList" });
    };

    init();

    return {
      ...toRefs(state),
      changeStepVal,
      stepEvent,
      getContent,
      ininTable,
      stepThree,
      handleSelectionChange,
      onSubmit,
    };
  },
};
</script>

<style lang="scss">
#programCompent {
  .el-date-editor {
    font-size: 0;
  }
  .form-button {
    text-align: left;
  }
}
.display {
  display: flex;
  .el-form {
    width: 500px;
  }
  .jlTableDiv {
    width: 894px;
    margin-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  #programCompent {
    overflow: auto;
    .el-date-editor {
      min-height: 40px !important;
      overflow: hidden;
    }
    .form-button {
      text-align: center;
    }
  }
}
</style>
