<template>
  <div class="flex" id="drag">
    <div class="left bordered">
      <el-divider>组件栏</el-divider>
      <a
        @click="currentItem = ''"
        href="javascript:void(0);"
        class="item first"
      >
        视图
      </a>
      <a
        href="javascript:void(0);"
        class="item"
        v-for="(item, index) in recList"
        :key="index"
        :style="{ backgroundColor: item.bg }"
        @click="onClickItem(item, index)"
      >
        【{{ index + 1 }}】{{ item.name }}
        <i class="el-icon-delete" @click="delRec(index)"></i>
      </a>
    </div>
    <div class="middle">
      <div class="buttons">
        <el-button-group>
          <el-button
            v-for="(item, index) in buttonList"
            :key="index"
            type="primary"
            :icon="'el-icon-' + item.icon"
            @click="addElement(index)"
          >
            {{ item.name }}
          </el-button>
        </el-button-group>
        <el-button-group>
          <el-button type="primary">{{ $t("i18n.preview") }}</el-button>
          <el-button class="cancel" type="primary" @click="changeStepVal(1)">
            {{ $t("i18n.back") }}
          </el-button>
          <el-button class="confirm" type="primary" @click="stepTwo">
            {{ $t("i18n.confirm") }}
          </el-button>
        </el-button-group>
      </div>
      <div class="area bordered">
        <div class="outside" @click="clearFocusFunc" :style="screenStyle">
          <Vue3DraggableResizable
            :isActive="true"
            v-for="(item, index) in recList"
            :key="index"
            :active="item.active"
            :max-width="screenStyle.width.split('px')[0]"
            :style="{ backgroundColor: item.bg }"
            :max-height="screenStyle.height.split('px')[0]"
            :w="item.width"
            :h="item.height"
            :x="item.left"
            :y="item.top"
            :parent="true"
            :parentLimitation="true"
            :parentW="screenStyle.width.split('px')[0]"
            :parentH="screenStyle.height.split('px')[0]"
            @activated="onFocus"
            @drag-end="onChangeFunc($event, item, index)"
            @resize-end="onChangeFunc($event, item, index)"
          ></Vue3DraggableResizable>
        </div>
      </div>
    </div>
    <div class="right bordered">
      <el-divider>属性栏</el-divider>
      <div>
        组件名：
        <span v-if="currentItem === ''">视图</span>
        <span v-else>{{ currentItem.name }}</span>
      </div>
      <el-divider>属性</el-divider>
      <template v-if="currentItem === ''">
        <div class="form-control">
          屏方向：
          <el-switch
            @change="directionChange"
            v-model="switchVal"
            active-text="横屏"
            active-value="1"
            inactive-text="竖屏"
            inactive-value="0"
          ></el-switch>
        </div>
        <div class="form-control">
          分辨率：
          <el-select
            @change="sizeChange"
            style="width: 117px"
            size="mini"
            v-model="selectVal"
            placeholder="请选择"
          >
            <el-option label="1920x1080" value="1920x1080"></el-option>
            <el-option label="1366x768" value="1366x768"></el-option>
            <el-option label="1280x800" value="1280x800"></el-option>
          </el-select>
        </div>
      </template>
      <template v-else>
        <div class="form-control">
          距左：
          <el-input-number
            size="mini"
            v-model="currentItem.left"
            :min="0"
            :max="screenStyle.width"
          ></el-input-number>
        </div>
        <div class="form-control">
          距上：
          <el-input-number
            size="mini"
            v-model="currentItem.top"
            :min="0"
            :max="screenStyle.height"
          ></el-input-number>
        </div>
        <el-button-group
          v-if="currentItem.flag < 2 || currentItem.flag > 6"
          class="form-control"
        >
          <el-button type="primary" @click="addResource">编辑资源</el-button>
          <el-button type="primary">
            资源数量&nbsp;{{ currentItem.resource.length }}
          </el-button>
        </el-button-group>
        <el-carousel
          @change="changeCarousel"
          :autoplay="false"
          v-if="currentItem.flag < 2 || currentItem.flag > 6"
          height="100px"
          indicator-position="outside"
        >
          <el-carousel-item v-for="item in currentItem.resource" :key="item">
            <img :src="item.link" style="object-fit: cover; width: 100%" />
          </el-carousel-item>
        </el-carousel>
        <div
          v-if="
            (currentItem.flag < 2 || currentItem.flag > 6) &&
            currentItem.resource.length > 0
          "
          class="form-control"
        >
          资源名：{{ currentItem.resource[carouselIndex].name }}
        </div>
        <div
          v-if="
            (currentItem.flag < 2 || currentItem.flag > 6) &&
            currentItem.resource.length > 0
          "
          class="form-control"
        >
          时长：
          <el-input-number
            size="mini"
            v-model="currentItem.duration"
            :min="0"
          ></el-input-number>
        </div>
        <div
          v-if="currentItem.flag == 2 || currentItem.flag == 3"
          class="form-control"
        >
          URL ：
          <el-input
            style="width: 128px"
            size="mini"
            v-model="currentItem.url"
          ></el-input>
        </div>
        <div v-if="currentItem.flag == 4" class="form-control">
          背景：
          <el-input
            type="color"
            style="width: 128px"
            size="mini"
            v-model="currentItem.bgColor"
          ></el-input>
        </div>
        <div v-if="currentItem.flag == 4" class="form-control">
          透明度：
          <el-slider v-model="currentItem.opacity"></el-slider>
        </div>
        <div v-if="currentItem.flag == 4" class="form-control">
          文本颜色：
          <el-input
            type="color"
            style="width: 103px"
            size="mini"
            v-model="currentItem.color"
          ></el-input>
        </div>
        <div v-if="currentItem.flag == 4" class="form-control">
          文本大小：
          <el-slider
            v-model="currentItem.size"
            style="width: 103px"
          ></el-slider>
        </div>
        <div v-if="currentItem.flag == 4" class="form-control">
          内容：
          <el-input
            type="textarea"
            style="width: 128px; vertical-align: top"
            :rows="2"
            v-model="currentItem.content"
          ></el-input>
        </div>
        <el-cascader
          @change="changeCityData"
          v-if="currentItem.flag == 6"
          :placeholder="$t('pleaseSelect')"
          :options="province"
          filterable
        ></el-cascader>
      </template>
    </div>
    <el-dialog
      :title="$t('资源选择')"
      width="80%"
      v-model="dialogSourceVisible"
      :close-on-click-modal="false"
    >
      <div id="addFileList">
        <el-card
          :class="{ checked: isObjInArray(currentItem.resource, item) > -1 }"
          v-for="(item, index) in matterList"
          :key="index"
          @click="editFileForCurrent(item)"
        >
          <template v-if="item.type < 5">
            <img :src="item.link" class="image" />
          </template>
          <template v-else-if="item.type < 11">
            <span class="document">文档</span>
          </template>
          <template v-else>
            <video :src="item.link" controls="controls">
              您的浏览器不支持视频功能。
            </video>
          </template>
          <span class="name">{{ item.name }}</span>
        </el-card>
      </div>
      <template footer>
        <el-button type="cancel" @click="dialogSourceVisible = false">
          {{ $t("close") }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, toRefs, watch } from "vue";
import Vue3DraggableResizable from "vue3-draggable-resizable";
//需引入默认样式
import "vue3-draggable-resizable/dist/Vue3DraggableResizable.css";
export default {
  name: "Drag",
  components: {
    Vue3DraggableResizable,
  },
  props: {
    screen: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    emit("public_header", false);
    const { proxy } = getCurrentInstance();
    const state = reactive({
      rec: "",
      recList: [],
      province: [],
      screenStyle: { width: "960px", height: "540px" },
      colorList: [
        "#A763EF",
        "#5cb85c",
        "#2179ED",
        "#EDB553",
        "#5bc0de",
        "#F12828",
        "#FF9880",
        "#1DACA0",
      ],
      buttonList: [
        { icon: "video-camera", name: "视频" },
        { icon: "picture", name: "图片" },
        { icon: "picture", name: "流媒体" },
        { icon: "picture", name: "网页" },
        { icon: "more", name: "跑马灯" },
        { icon: "time", name: "时钟" },
        { icon: "more", name: "天气" },
        { icon: "document", name: "文档" },
      ],
      switchVal: "1",
      selectVal: "1920x1080",
      left: 0,
      top: 0,
      currentItem: "",
      dialogSourceVisible: false,
      formInline: {
        name: "",
        current: 1,
        size: 10,
        status: 1,
      },
      matterList: [],
      carouselIndex: 0,
    });

    watch(
      () => props.screen,
      () => {
        //通过一个函数返回要监听的属性
        editData();
      },
      {
        deep: true,
      }
    );

    const init = () => {
      if (props.screen) {
        editData();
      }
      state.province = proxy.$defined.getProvinceData();
    };

    const changeStepVal = (val) => {
      emit("step-msg2", val);
    };

    const editData = () => {
      let obj = JSON.parse(props.screen);
      state.selectVal = obj.resolution;
      state.switchVal = obj.direction;
      obj.content.map((item) => {
        item.width = item.w > 0 ? item.w : item.width;
        item.height = item.h > 0 ? item.h : item.height;
        item.top = item.y > 0 ? item.y : item.top;
        item.left = item.x > 0 ? item.x : item.left;
      });
      state.recList = obj.content;
      if (obj.direction == 0) {
        directionChange();
      }
    };

    const stepTwo = () => {
      let obj = {
        name: "视图",
        resolution: state.selectVal,
        direction: state.switchVal, // 0竖屏 1横屏
        content: state.recList,
      };
      changeStepVal(3);
      emit("content", JSON.stringify(obj));
    };

    const addResource = async () => {
      getMatterByType(state.currentItem.flag);
      let { data } = await proxy.$api.advert.matterList(state.formInline);
      state.matterList = data.records;
      state.dialogSourceVisible = true;
    };

    const delRec = (index) => {
      proxy.$defined.confirm(
        () => {
          state.recList.splice(index, 1);
        },
        "是否删除组件【" + state.recList[index].name + "】",
        "是否继续"
      );
    };

    const isObjInArray = (arr, obj) => {
      return arr.findIndex((v) => {
        return v.id == obj.id;
      });
    };

    const editFileForCurrent = (item) => {
      // 编辑图片、视频等资源
      let data = state.currentItem.resource,
        index = isObjInArray(data, item);
      index == -1 ? data.push(item) : data.splice(index, 1);
    };

    const getMatterByType = (type) => {
      delete state.formInline.type_le;
      delete state.formInline.type_ge;
      switch (type) {
        case 1: // 图片
          state.formInline.type_le = 4;
          break;
        case 0: // 视频
          state.formInline.type_ge = 10;
          break;
        case 7: // 文档
          state.formInline.type_le = 10;
          state.formInline.type_ge = 5;
          break;
        default:
          break;
      }
    };

    const changeCarousel = (index) => {
      // 幻灯片切换时触发
      state.carouselIndex = index;
    };

    const addElement = (flag) => {
      // 点击增加元素
      var len = state.recList.filter((row) => row.flag === flag).length + 1;
      let obj = {
        width: 100,
        height: 100,
        top: 0,
        left: 0,
        duration: 0,
        active: false,
        bg: state.colorList[flag],
        flag: flag,
        name: state.buttonList[flag].name + len,
      };
      state.recList.push(obj);
    };

    const clearFocusFunc = (e) => {
      let target = e ? e.target.className : "";
      if (target.indexOf("vdr") == -1) {
        state.recList.map((item) => (item.active = false));
      }
    };

    const onFocus = (index) => {
      clearFocusFunc();
      state.recList[index].active = true;
    };

    const onChangeFunc = (params, item, index) => {
      for (let k in params) {
        if (k == "x") {
          item.left = params[k];
        } else if (k == "y") {
          item.top = params[k];
        } else if (k == "w") {
          item.width = params[k];
        } else if (k == "h") {
          item.height = params[k];
        } else {
          item[k] = params[k];
        }
      }
      onClickItem(item, index);
    };

    const directionChange = () => {
      // 改变屏幕方向
      let { width, height } = getCurrentSize();
      state.screenStyle = {
        width: state.switchVal == 1 ? width : height,
        height: state.switchVal == 1 ? height : width,
        transform: state.switchVal == 1 ? "translateY(-50%)" : "translateY(0%)",
        top: state.switchVal == 1 ? "50%" : "0",
        margin: state.switchVal == 1 ? "0 auto" : "20px auto",
      };
    };

    const getCurrentSize = () => {
      let obj = {
        width: parseInt(state.selectVal.split("x")[0]) / 2 + "px",
        height: parseInt(state.selectVal.split("x")[1]) / 2 + "px",
      };
      return obj;
    };

    const sizeChange = () => {
      // 改变屏幕大小
      state.screenStyle = getCurrentSize();
      directionChange();
    };

    const onClickItem = (item, index) => {
      // 点击左侧组件栏
      onFocus(index);
      state.currentItem = item;
      state.currentItem.resource = state.currentItem.resource || [];
      state.currentItem.index = index;
    };

    init();

    return {
      ...toRefs(state),
      sizeChange,
      onChangeFunc,
      changeCarousel,
      addElement,
      editFileForCurrent,
      delRec,
      addResource,
      changeStepVal,
      stepTwo,
      onClickItem,
      directionChange,
      isObjInArray,
    };
  },
};
</script>

<style lang="scss">
#drag {
  min-width: 1420px;
  .bordered {
    border: 1px solid $border_color;
  }
  .left,
  .right {
    width: 200px;
    flex-shrink: 0;
    padding: 0 10px;
  }
  .left {
    a.item {
      width: 100%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 2px;
      display: block;
      color: #fff;
      margin-bottom: 5px;
      i {
        position: relative;
        z-index: 3;
        &:hover {
          color: red;
        }
      }
    }
    a.item.first {
      background: $border_color;
    }
  }
  .middle {
    padding: 0 10px;
    // 删除边框
    .vdr.active:before {
      outline: none;
    }
    .buttons {
      .el-button--primary {
        border-radius: 2px;
        min-width: 95px;
      }
      .el-button--primary.cancel {
        background: $btn_reset_bg;
        border-color: $btn_reset_bg;
      }
      .el-button-group {
        &:last-child {
          .el-button--primary {
            min-width: 70px;
          }
        }
      }
    }
    .area {
      width: 1000px;
      height: 580px;
      margin-top: 10px;
      background: $body_bg;
      overflow: auto;
      .outside {
        background: #fff;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
      }
    }
  }
  .right {
    .form-control {
      margin-bottom: 20px;
    }
    .el-button {
      border-radius: 2px;
      min-width: 40px;
    }
    .el-button.danger {
      background: $orange1_color;
      border-color: $orange1_color;
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 18px;
      opacity: 0.75;
      line-height: 100px;
      margin: 0;
    }
    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }
    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
    .el-slider {
      width: 117px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
#addFileList {
  display: flex;
  flex-flow: row wrap;
  .el-card {
    width: 200px;
    margin: 10px;
    border: 2px solid transparent;
    position: relative;
    img,
    video,
    .document {
      width: 174px;
      height: 174px;
      object-fit: cover;
    }
    span.name {
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
    }
    .el-card__body {
      padding: 10px;
    }
  }
  .el-card.checked {
    border: 2px solid #67c23a;
    &:after {
      content: "✔";
      position: absolute;
      display: block;
      top: 5px;
      left: 10px;
      font-size: 20px;
      z-index: 102;
      color: #fff;
    }
    &:before {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      content: "";
      z-index: 101;
      border-top: 60px solid #67c23a;
      border-right: 60px solid transparent;
    }
  }
}
</style>
