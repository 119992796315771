<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.createProgram')"
    id="createProgram"
  >
    <div id="outer-title">{{ $t("i18n.createProgram") }}</div>
    <el-steps :active="step" simple style="margin-bottom: 20px">
      <el-step title="节目基础信息" icon="el-icon-reading"></el-step>
      <el-step title="节目内容编辑" icon="el-icon-edit"></el-step>
      <el-step title="选择发布终端" icon="el-icon-house"></el-step>
      <el-step title="节目确认保存" icon="el-icon-lock"></el-step>
    </el-steps>
    <program @step-msg="stepEvent"></program>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import program from "./Program";
export default {
  name: "CreateProgram",
  components: {
    program,
  },
  setup(props, { emit }) {
    emit("public_header", true);

    const state = reactive({
      step: 1,
    });

    const stepEvent = (data) => {
      state.step = data;
    };

    return {
      ...toRefs(state),
      stepEvent,
    };
  },
};
</script>

<style lang="scss"></style>
